<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        to <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName | uppercase }}</CBadge></h3>
        Restaurant
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'RestaurantLinkedZIPCodes'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <!-- Filters -->
      <CCard class="filters" accent-color="warning">
        <CCardHeader
          role="button"
          class="text-warning shadow-none card-header"
          @click="resetFilters"
        >
          <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
          <div class="card-header-actions">
            <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
          </div>
        </CCardHeader>

        <CCollapse :show="$store.state.filterShow">
          <CCardBody class="p-2">
            <div class="row">
              <!-- Country Filter -->
              <div class="col-md-4 col-filter mb-2 mb-lg-0">
                <v-select
                  class="v-select-filter"
                  placeholder="Country.."
                  :value="country"
                  :options="authCountries"
                  :reduce="c => c.value"
                  :searchable="false"
                  @input="countryFilter"
                />
              </div>

              <!-- Search -->
              <div class="col-md col-filter mb-2 mb-lg-0">
                <CInput
                  class="mb-0"
                  type="search"
                  placeholder="Search.."
                  v-model="search"
                  @input="searchFilter"
                />
              </div>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>

      <CForm v-if="!loading">
        <CRow>
          <CCol lg="12">
            <h5>Select ZIP codes..</h5>
            <span class="d-block my-3">
              <strong>{{ selectedOptions.length }}</strong> item(s) selected
            </span>

            <!--
              CMultiSelect, this.options ile bind edilemedi. Bug olabilir.
              CInputCheckbox ise v-model="selectedOptions" desteklemiyor
            -->
            <ul>
              <li v-for="option in options" v-bind:key="option.id" class="my-2">
                <label>
                  <input
                    type="checkbox"
                    v-model="selectedOptions"
                    :value="option.id"
                  />
                  {{ option.zcZip }} - {{ option.zcCity }}
                </label>
              </li>
              <li v-if="options.length == 0"><strong>No ZIP codes found!</strong></li>
            </ul>

          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter class="sticky">
      <CButton
        color="primary"
        :disabled="submitted || selectedOptions.length == 0"
        @click="store()"
      >
        Submit
        <strong v-if="selectedOptions.length > 0">({{ selectedOptions.length }})</strong>
      </CButton>

    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted" />
    <CElementCover :opacity="0.4" v-show="loading">
      <CSpinner size="5xl" grow color="primary" />
    </CElementCover>

  </CCard>

</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'AddNewLinkedZIPCode',

  data() {
    return {
      options: [],
      selectedOptions: [],
      search: null,
      submitted: false,
      loading: false,

      restaurantName: "",

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getOptions();
    }, 1000);
  },

  mounted() {
    this.getOptions()
    this.getRestaurantName();
  },

  computed: {
    restaurantId() {
      return this.$route.params.restaurant_id;
    },
    country() {
      return this.$store.state.filters.country || this.$store.state.countryId
    }
  },

  methods: {
    getRestaurantName() {
      this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId))
        .then((response) => {
          this.restaurantName = response.data.restaurant_name;
        })
        .catch((error) => { this.restaurantName = 'Not Found' })
    },

    store () {
      if (this.selectedOptions.length > 0) {
        this.submitted = true

        this.$axios.post(this.$backend.RESTAURANT_LINKED_ZIPCODES.STORE.replace("{restaurant_id}", this.restaurantId), this.selectedOptions)
        .then(()=>{
          this.successAlertMessage = {
            itemName: this.selectedOptions.length,
            message:
              this.selectedOptions.length > 1
                ? `ZIP codes were linked to ${this.restaurantName}.`
                : `ZIP code was linked to ${this.restaurantName}.`,
          };
          this.dismissSuccessAlert = this.dismissSecs

          // this.getOptions() kullanıp listeyi yenilemek yerine istemci tarafında..
          // seçili seçenekleri (selectedOptions) listeden (options) çıkar.
          this.selectedOptions.forEach((item) => {
            const index = this.options.findIndex(o => o.id === item);
            this.options.splice(index, 1);
          });
          this.selectedOptions = [];

        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          window.scrollTo(0,0);
          this.submitted = false
        })

      }
    },

    getOptions () {
      this.loading = true;
      this.$axios
        .get(
          this.$backend.RESTAURANT_LINKED_ZIPCODES.UNLINKED_ZIPCODES.replace(
            "{restaurant_id}",
            this.restaurantId
          ),
          { params: { country: this.country, search: this.search } }
        )
        .then((response) => {
          this.options = response.data;
        })
        .catch((error) => {
          console.log("Error: ", error.response.data);
        })
        .finally(() => { this.loading = false; })
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getOptions();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.search = "";
        this.getOptions();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  }
}
</script>


<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

ul {
  list-style-type: none;
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;

  background-color: #e6e6fa;
  border-radius: 10px;
  padding: 40px;
}

input[type="checkbox"], label {
    cursor: pointer;
}
</style>
